$colors: (white: #FFFFFF, black: #000000, gray: #808080, lightgray: #CCCCCC, verylightgray: #E8E8E8, smallgray: #888888, green: #15c715, red: #FF0000, orange: #ff8a00, yellow: #ffe400, blue: #2d1881, lightbg: #6551e6, medbg: #553fcf, meddarkbg: #4b21c0, darkbg: #22055c, analogouslightbg: #f20dcc, analogouslightbg2: #a20df2, analogousmeddarkbg: #160c4f, analogousdarkbg: #220449, analogousdarkdarkbg: #17082b, analogousverydarkbg: #12091b, darkmedbg: #0b0335, darkmedmedbg: #1d08a0, darkmeddarkbg: #15066b, darkdarkbg: #12055c, darklightbg: #250abd, dawnmedbg: #0b697f, dawnmedmedbg: #7cf20d, dawnmeddarkbg: #096aae, dawndarkbg: #045218, lightmedbg: #a498ec, lightmedlightbg: #bab0f3, lightlightbg: #d5cffc, lightdarkbg: #473c86, lightverylightbg: #f7f5fe, pastellightbg: #cecafc, pastelverylightbg: #f2f0fe, pastelmedbg: #749a86, pastelmedlightbg: #acddc9, pasteldarkbg: #5ac4a4, sunsetdarkbg: #c77cda, sunsetmedlightbg: #2f0df2, sunsetmeddarkbg: #7e5dac, sunsetmedmedbg: #0a4ac2, sunsetmedbg: #516ebd, sunsetdarkdarkbg: #65b530, sunsetverydarkbg: #040d4e, verydarkbg: #160335, verylightbg: #d5cffc, verylightbg2: #d0caed, verylightbg3: #b0a3fa);
;
$defaultColors: (white: #FFFFFF, black: #000000, gray: #808080, mediumgray: #333333, lightgray: #CCCCCC, verylightgray: #E8E8E8, smallgray: #888888, red: #FF0000, maroon: #a40000, spring: #76fa67, green: #17b900, forest: #177612, yellow: #ffff00, gold: #d9b800, goldenrod: #ffce0d, orange: #ff9115, silver: #b1d0da, skyblue: #17c6ff, blue: #185281, trueblue: #105289, darkblue: #00489f, blueviolet: #5400f0, periwinkle: #bfc1ff, lavender: #dab0ff, purple: #a611ff, magenta: #ff11e8, babypink: #ffbbdf, brown: #8a3913, indigo: #4222bf);
;

/*--------------------------------------------------------------
Colours and backgrounds for common.css
-------------------------------------------------------------- */
html, body {
	color: map-get($defaultColors, mediumgray);
	background: map-get($colors, darkbg);
}

body{
	background: map-get($colors, verydarkbg);
	background: linear-gradient(0deg, map-get($colors, analogousverydarkbg) 0%, map-get($colors, darkbg) 100%);
}

h1 {
	color: map-get($defaultColors, white);
}

h2 {
	color: map-get($colors, fadeddarkbg);
}

h3 {
	color: map-get($colors, medmedbg);
}

hr {
	border-color: map-get($defaultColors, white);
	border-top-color: map-get($defaultColors, lightgray);
}

#page-body{
	background: map-get($defaultColors, white);
}

#page-header{
	background: map-get($colors, medbg);
}

pre{
	background: map-get($defaultColors, white);
}

/* Search box
--------------------------------------------- */

.search-box .inputbox,
.search-box .inputbox:hover,
.search-box .inputbox:focus,
.search-box .button:hover {
	border-color: #C7C3BF; /* beige */
	text-shadow: none;
}

/* Round cornered boxes and backgrounds
---------------------------------------- */
.headerbar {
	color: map-get($defaultColors, white);
}

#page-footer {
	background-color: map-get($colors, verylightbg);
}

.forum-category-img path{
	fill: map-get($colors, darkbg);
}

.forabg .inner .topiclist{
	.header{ 
		.icon dt .list-inner a{
			transition: background 2s ease;
			transition-duration: 0.5s;
		}
	}
}
.bgcolor1, .bgcolor2{
	background: map-get($defaultColors, white);
}
.panel {
	background-color: map-get($defaultColors, verylightgray);
	color: map-get($colors, verydarkbg);
}

.post:target .content {
	color: map-get($defaultColors, black);
}

.post:target h3 a {
	color: map-get($defaultColors, black);
}

.bg1 {
	background-color: map-get($colors, verylightbg);
}

table.zebra-list tr:nth-child(odd) td, ul.zebra-list li:nth-child(odd) {
	background-color: map-get($defaultColors, verylightgray);
}

.bg2 {
	background-color: map-get($colors, verylightbg);
}

table.zebra-list tr:nth-child(even) td, ul.zebra-list li:nth-child(even) {
	background-color: map-get($colors, verylightbg);
}

.bg3	{
	background-color: map-get($colors, verylightbg);
}

.ucprowbg {
	background-color: map-get($defaultColors, lightgray);
}

.fieldsbg {
	background-color: map-get($defaultColors, verylightgray);
}

a.topictitle{
	color: map-get($defaultColors, white);
	&:hover{
		color: map-get($defaultColors, white);
	}
}
/* Horizontal lists
----------------------------------------*/

ul.navlinks {
	border-top-color: map-get($defaultColors, white);
}

/* Table styles
----------------------------------------*/
table.table1 thead{
	th{
		background-color: map-get($colors, medbg);
		color: map-get($defaultColors, white);
	}
}

table.table1 thead th {
	color: map-get($defaultColors, white);
}

table.table1 tbody tr {
	border-color: map-get($defaultColors, lightgray);
}

table.table1 tbody tr:hover, table.table1 tbody tr.hover {
	background-color: map-get($defaultColors, verylightgray);
	color: map-get($defaultColors, black);
}

table.table1 td {
	color: map-get($colors, darkbg);
}

table.table1 tbody td {
	border-top-color: map-get($defaultColors, verylightgray);
}

table.table1 tbody th {
	border-bottom-color: map-get($defaultColors, black);
	color: map-get($defaultColors, mediumgray);
	background-color: map-get($defaultColors, white);
}

table.info tbody th {
	color: map-get($defaultColors, black);
}

/* Misc layout styles
---------------------------------------- */
dl.details dt {
	color: map-get($defaultColors, black);
}

dl.details dd {
	color: #536482;
}

.sep {
	color: #1198D9;
}

/* Pagination
---------------------------------------- */

.pagination li a {
	background-color: map-get($defaultColors, verylightgray);
	border-color: map-get($defaultColors, lightgray);
	color: map-get($colors, medbg);
}

.pagination li.ellipsis span {
	background-color: transparent;
	color:	map-get($defaultColors, black);
}

.pagination li.active span {
	background-color: map-get($colors, medbg);
	border-color: map-get($colors, medbg);
	color: map-get($defaultColors, white);
}

.pagination li a:hover, .pagination .dropdown-visible a.dropdown-trigger, .nojs .pagination .dropdown-container:hover a.dropdown-trigger {
	background-color: map-get($colors, medbg);
	border-color: map-get($colors, medbg);
	color: map-get($defaultColors, white);
}
.dropdown-menu{
	background: none;
}

/* Miscellaneous styles
---------------------------------------- */

.copyright {
	color: #555555;
}

.error {
	color: #BC2A4D;
}

.reported {
	background-color: #F7ECEF;
}

li.reported:hover {
	background-color: #ECD5D8 !important;
}

div.rules {
	background-color: #ECD5D8;
	color: #BC2A4D;
}

p.post-notice {
	background-color: #ECD5D8;
	background-image: none;
}

.post-buttons li {
	&:hover{
		.fa-heart, .fa-fire, .fa-gavel {
			path{
				color: map-get($defaultColors, black);
			}
		}
	}
	.fa-heart, .fa-fire, .fa-gavel{ 
		path{
			color: map-get($colors, medbg);
		}
	}
}
/*
--------------------------------------------------------------
Colours and backgrounds for links.css
-------------------------------------------------------------- */

a { color: map-get($colors, medmedbg); }
a:hover	{ color: map-get($colors, darkbg); }

/* Links on gradient backgrounds */
.forumbg .header a, .forabg .header a, th a {
	color: map-get($defaultColors, white);
}

.forumbg .header a:hover, .forabg .header a:hover, th a:hover {
	color: map-get($defaultColors, white);
	text-shadow: 1px 1px 2px map-get($defaultColors, black);
}

/* Notification mark read link */
.dropdown-extended a.mark_read {
	background-color: map-get($defaultColors, white);
}

/* Post body links */
.postlink {
	border-bottom-color: #368AD2;
	color: #368AD2;
}

.postlink:visited {
	border-bottom-color: #5D8FBD;
	color: #5D8FBD;
}

.postlink:hover {
	background-color: #D0E4F6;
	color: #0D4473;
}

.signature a, .signature a:hover {
	background-color: transparent;
}

/* Back to top of page */
a.top {
}

/* Arrow links  */

a.arrow-up:hover {
	background-color: transparent;
}

a.arrow-left:hover {
	color: #368AD2;
}

a.arrow-right:hover {
	color: #368AD2;
}

/*--------------------------------------------------------------
Colours and backgrounds for content.css
-------------------------------------------------------------- */
ul.topiclist{
	transition: border 0.4s ease-in;
	&.topics li:hover + li.row{
		border-color: map-get($colors, medmedbg);
	}
	&:hover + ul{
		border-color: map-get($colors, medmedbg);
	}
	li.rowtitle{
		transition: 0.4s ease-in;
		a{
			text-decoration: none;
		}
	}
}

ul.topiclist li {
	color: map-get($defaultColors, mediumgray);
}
ul.topiclist.topics div.col-md-4 > .rowtitle{
	background: map-get($colors, lightbg);
	color: map-get($defaultColors, white);
	transition: background 2s ease;
	transition-duration: 0.8s;
}
ul.topiclist.topics div.col-md-4 > .rowtitle a{
	color: map-get($defaultColors, white);
}
ul.topiclist.topics div.col-md-4 > .rowtitle small{
	color: map-get($defaultColors, lightgray);
}
ul.topiclist div.col-md-4 > .rowtitle:hover{
	background: map-get($colors, medmedbg);
	color: map-get($defaultColors, white);
	+ li{
		border-color: map-get($colors, medmedbg);
	}
}
ul.topiclist.topics div li.row dl strong{
	color: map-get($defaultColors, white);
	background: map-get($colors, darkbg);
}
li.row {
	transition: 0.5s ease-in;
}

li.row strong {
	color: map-get($defaultColors, black);
}

li.row:hover dd {
	border-left-color: map-get($defaultColors, lightgray);
}

.rtl li.row:hover dd {
	border-right-color: map-get($defaultColors, lightgray);
	border-left-color: transparent;
}

li.header dt, li.header dd {
	color: map-get($defaultColors, white);
}

/* Post body styles
----------------------------------------*/
.postbody {
	color: map-get($defaultColors, mediumgray);
}

#topicreview .postbody{
	background: none;
}

/* Content container styles
----------------------------------------*/
.content {
	color: map-get($defaultColors, mediumgray);
}

.content h2, .panel h2 {
	color: #115098;
	border-bottom-color: map-get($defaultColors, gray);
}

dl.faq dt {
	color: map-get($defaultColors, mediumgray);
}

.posthilit {
	background-color: #F3BFCC;
	color: #BC2A4D;
}

.announce, .unreadpost {
	/* Highlight the announcements & unread posts box */
}

/* Post signature */
.signature {
	border-top-color: map-get($defaultColors, lightgray);
}

/* Post noticies */
.notice {
	border-top-color: map-get($defaultColors, gray);
}

/* BB Code styles
----------------------------------------*/
/* Quote block */
blockquote {
	background-color: #EBEADD;
	border-color:#DBDBCE;
}

blockquote blockquote {
	/* Nested quotes */
	background-color:#EFEED9;
}

blockquote blockquote blockquote {
	/* Nested quotes */
	background-color: #EBEADD;
}

/* Code block */
.codebox {
	background-color: map-get($defaultColors, white);
	border-color: #C9D2D8;
}

.codebox p {
	border-bottom-color: map-get($defaultColors, gray);
}

.codebox code {
	color: #2E8B57;
}

.syntaxbg		{ color: map-get($defaultColors, white); }
.syntaxcomment	{ color: #FF8000; }
.syntaxdefault	{ color: #0000BB; }
.syntaxhtml		{ color: map-get($defaultColors, black); }
.syntaxkeyword	{ color: #007700; }
.syntaxstring	{ color: #DD0000; }

/* Attachments
----------------------------------------*/
.attachbox {
	background-color: map-get($defaultColors, white);
	border-color:  #C9D2D8;
}

.pm-message .attachbox {
	background-color: #F2F3F3;
}

.attachbox dd {
	border-top-color: #C9D2D8;
}

.attachbox p {
	color: #666666;
}

.attachbox p.stats {
	color: #666666;
}

.attach-image img {
	border-color: #999999;
}

/* Inline image thumbnails */

dl.file dd {
	color: #666666;
}

dl.thumbnail img {
	border-color: #666666;
	background-color: map-get($defaultColors, white);
}

dl.thumbnail dd {
	color: #666666;
}

dl.thumbnail dt a:hover {
	background-color: #EEEEEE;
}

dl.thumbnail dt a:hover img {
	border-color: #368AD2;
}

.stats dl.details dd{
	background: map-get($colors, verylightbg3);
}

/* Post poll styles
----------------------------------------*/

fieldset.polls dl {
	border-top-color: #DCDEE2;
	color: #666666;
}

fieldset.polls dl.voted {
	color: map-get($defaultColors, black);
}

fieldset.polls dd div {
	color: map-get($defaultColors, white);
}

.rtl .pollbar1, .rtl .pollbar2, .rtl .pollbar3, .rtl .pollbar4, .rtl .pollbar5 {
	border-right-color: transparent;
}

.pollbar1 {
	background-color: #AA2346;
	border-bottom-color: #74162C;
	border-right-color: #74162C;
}

.rtl .pollbar1 {
	border-left-color: #74162C;
}

.pollbar2 {
	background-color: #BE1E4A;
	border-bottom-color: #8C1C38;
	border-right-color: #8C1C38;
}

.rtl .pollbar2 {
	border-left-color: #8C1C38;
}

.pollbar3 {
	background-color: #D11A4E;
	border-bottom-color: #AA2346;
	border-right-color: #AA2346;
}

.rtl .pollbar3 {
	border-left-color: #AA2346;
}

.pollbar4 {
	background-color: #E41653;
	border-bottom-color: #BE1E4A;
	border-right-color: #BE1E4A;
}

.rtl .pollbar4 {
	border-left-color: #BE1E4A;
}

.pollbar5 {
	background-color: #F81157;
	border-bottom-color: #D11A4E;
	border-right-color: #D11A4E;
}

.rtl .pollbar5 {
	border-left-color: #D11A4E;
}

/* Poster profile block
----------------------------------------*/
.postprofile {
	color: #666666;
	border: none;
}

.pm .postprofile {
	border-color: #DDDDDD;
}

.postprofile strong {
	color: map-get($defaultColors, black);
}

dd.profile-warnings {
	color: #BC2A4D;
}

/*
--------------------------------------------------------------
Colours and backgrounds for buttons.css
-------------------------------------------------------------- */
.button {
	border-color: #C7C3BF;
	background-color: map-get($defaultColors, white);
	-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorStr='#FFFFFF', EndColorStr='#E9E9E9')";
	box-shadow: 0 0 0 1px #FFFFFF inset;
	-webkit-box-shadow: 0 0 0 1px #FFFFFF inset;
	color: map-get($colors, medmedbg);
}

.dropdown-select {
	color: #536482;
}

.button:hover, .dropdown-visible .dropdown-select, .nojs .dropdown-container:hover .dropdown-select {
	border-color: #0a8ed0;
	background-image: -moz-linear-gradient(top, #E9E9E9, #FFFFFF);
	background-image: -webkit-linear-gradient(top, #E9E9E9, #FFFFFF);
	background-image: -o-linear-gradient(top, #E9E9E9, #FFFFFF);
	background-image: linear-gradient(to bottom, #E9E9E9, #FFFFFF);
	-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorStr='#E9E9E9', EndColorStr='#FFFFFF')";
	text-shadow: 1px 1px 0 #FFFFFF, -1px -1px 0 #FFFFFF, -1px -1px 0 rgba(188, 42, 77, 0.2);
}

.dropdown-select:after	{ border-color: #DADADA; }
.dropdown-select:hover	{ border-color: #C7C3BF; }

.dropdown-visible .dropdown-select, .dropdown-visible .dropdown-select:hover, .nojs .dropdown-container:hover .dropdown-select {
	border-color: #A6B2BA;
	color: map-get($defaultColors, blue);
}

.contact-icon { background-image: url("../../images/icons_contact.png"); }
.contact-icons a		{ border-color: #DCDCDC; }
.contact-icons a:hover	{ background-color: #F2F6F9; }

/*
--------------------------------------------------------------
Colours and backgrounds for cp.css
-------------------------------------------------------------- */

/* Main CP box
----------------------------------------*/

.panel-container h3{
	border-color: #A4B3BF;
}
.panel-container hr, #cp-menu hr {
	border-color: #e9e9e9;
}

.panel-container .panel li.row {
	border-bottom-color: #B5C1CB;
	border-top-color: #F9F9F9;
}

.panel-container .panel li.header dd, .panel-container .panel li.header dt {
	color: map-get($defaultColors, black);
}

.panel-container table.table1 thead th {
	background: map-get($colors, medbg);
	color: map-get($defaultColors, white);
}

#cp-main .pm-message {
	border-color: #DBDEE2;
	background-color: map-get($defaultColors, white);
}

/* CP tabbed menu or Chevron Tabs
----------------------------------------*/
#ucp-tabs, #chevron-tabs{
	 ul li a{
		background: map-get($colors, darkbg);
		color: map-get($defaultColors, white);
		&:after {  
			border-top: 15px solid transparent;
			border-bottom: 15px solid transparent;
			border-left: 15px solid map-get($colors, darkbg);
		}
		&::after {  
			border-top: 15px solid transparent;
			border-bottom: 15px solid transparent;
			border-left: 15px solid map-get($colors, darkbg);
		}
		&:before { 
			border-top: 15px solid transparent;
			border-bottom: 15px solid transparent;
			border-left: 15px solid map-get($defaultColors, white);
		}	
		&::before { 
			border-top: 15px solid transparent;
			border-bottom: 15px solid transparent;
			border-left: 15px solid map-get($defaultColors, white);
		}	
		&:hover {
			background: map-get($colors, verydarkbg);
			&:after {
				border-left-color: map-get($colors, verydarkbg);
			}
			&::after {
				border-left-color: map-get($colors, verydarkbg);
			}
		}
	}
	ul li.activetab a{
		background: map-get($colors, meddarkbg);
		&:after {  
			border-top: 15px solid transparent;
			border-bottom: 15px solid transparent;
			border-left: 15px solid map-get($colors, meddarkbg);
		}
		&::after {  
			border-top: 15px solid transparent;
			border-bottom: 15px solid transparent;
			border-left: 15px solid map-get($colors, meddarkbg);
		}
		&:hover {
			background: map-get($colors, verydarkbg);
			&:after {
				border-left-color: map-get($colors, verydarkbg);
			}
			&::after {
				border-left-color: map-get($colors, verydarkbg);
			}
		}
	}
}	
/* Mini tabbed menu used in MCP
----------------------------------------*/
#minitabs .tab > a {
	background-color: map-get($colors, verylightbg);
}

#minitabs .activetab > a,
#minitabs .activetab > a:hover {
	background-color: #F9F9F9;
	color: map-get($defaultColors, mediumgray);
}

/* Responsive tabs
----------------------------------------*/
.responsive-tab .responsive-tab-link:before {
	border-color: #536482;
}

.responsive-tab .responsive-tab-link:hover:before {
	border-color: #D31141;
}

/* UCP navigation menu
----------------------------------------*/

/* Link styles for the sub-section links */
#navigation a {
	color: map-get($defaultColors, white);
	background: map-get($colors, lightbg);
}

.rtl #navigation a {
	background: #B4C4D1;
	background: -moz-linear-gradient(left, map-get($colors, verylightbg), 0%, #B4C4D1 50%);
	background: -webkit-gradient(left top, right top, color-stop(0%, map-get($colors, verylightbg)), color-stop(50%, #B4C4D1));
	background: -webkit-linear-gradient(left, map-get($colors, verylightbg), 0%, #B4C4D1 50%);
	background: -o-linear-gradient(left, map-get($colors, verylightbg), 0%, #B4C4D1 50%);
	background: -ms-linear-gradient(left, map-get($colors, verylightbg), 0%, #B4C4D1 50%);
	background: linear-gradient(to right, map-get($colors, verylightbg), 0%, #B4C4D1 50%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='map-get($colors, verylightbg)', endColorstr='#B4C4D1', GradientType=1 );
}

#navigation a:hover {
	background: map-get($colors, medmedbg);
	color: map-get($defaultColors, white);
}

#navigation #active-subsection a {
	color: map-get($defaultColors, white);
	background: map-get($colors, meddarkbg);
}

#navigation #active-subsection a:hover {
	background: map-get($colors, darkbg);
}
#cp-menu #navigation li{
	a{
		transition: 0.4s ease-in;
	}
	&:hover a{
		background: map-get($colors, darkbg);
	}
}

@media only screen and (max-width: 900px), only screen and (max-device-width: 900px)
{
	#navigation a, .rtl #navigation a {
		background: map-get($defaultColors, mediumgray);
	}
}

/* Preferences pane layout
----------------------------------------*/
.panel-container h2 {
	color: map-get($defaultColors, mediumgray);
}

.panel-container .panel {
	background-color: #F9F9F9;
}

#cp-main .pm {
	background-color: map-get($defaultColors, white);
}

/* Friends list */

dl.mini dt {
	color: #425067;
}

/* PM Styles
----------------------------------------*/
/* PM Message history */
.current {
	color: map-get($defaultColors, black) !important;
}

/* PM marking colours */
.pmlist li.pm_message_reported_colour, .pm_message_reported_colour {
	border-left-color: #BC2A4D;
	border-right-color: #BC2A4D;
}

.pmlist li.pm_marked_colour, .pm_marked_colour {
	border-color: #FF6600;
}

.pmlist li.pm_replied_colour, .pm_replied_colour {
	border-color: #A9B8C2;
}

.pmlist li.pm_friend_colour, .pm_friend_colour {
	border-color: #5D8FBD;
}

.pmlist li.pm_foe_colour, .pm_foe_colour {
	border-color: map-get($defaultColors, black);
}

/* Avatar gallery */
#gallery label {
	background: map-get($defaultColors, white);
	border-color: map-get($defaultColors, lightgray);
}

#gallery label:hover {
	background-color: #EEE;
}

#viewfolder a.topictitle:hover{
	color: map-get($colors, medbg);
}

/*
--------------------------------------------------------------
Colours and backgrounds for forms.css
-------------------------------------------------------------- */

/* General form styles
----------------------------------------*/
select {
	background-color: map-get($defaultColors, white);
	color: map-get($defaultColors, black);
}

label {
	color: #425067;
}

option.disabled-option {
	color: graytext;
}

/* Definition list layout for forms
---------------------------------------- */
dd label {
	color: map-get($defaultColors, mediumgray);
}

fieldset.fields1 {
	background-color: transparent;
}

/* Hover effects */
fieldset dl:hover dt label {
	color: map-get($defaultColors, black);
}

fieldset.fields2 dl:hover dt label {
	color: inherit;
}

/* Quick-login on index page */
fieldset.quick-login input.inputbox {
	background-color: #F2F3F3;
}

/* Posting page styles
----------------------------------------*/
#postingbox{
	border: 5px solid map-get($colors, lightbg);
}

#message-box textarea {
	color: map-get($defaultColors, mediumgray);
}

#message-box textarea.drag-n-drop {
	outline-color: rgba(102, 102, 102, 0.5);
}

#message-box textarea.drag-n-drop-highlight {
	outline-color: rgba(17, 163, 234, 0.5);
}

/* Input field styles
---------------------------------------- */
.inputbox {
	background-color: map-get($defaultColors, white);
	border-color: #B4BAC0;
	color: map-get($defaultColors, mediumgray);
}

.inputbox:-moz-placeholder {
	color: map-get($defaultColors, mediumgray);
}

.inputbox::-webkit-input-placeholder {
	color: map-get($defaultColors, mediumgray);
}

.inputbox:hover {
	border-color: #11A3EA;
}

.inputbox:focus {
	border-color: #11A3EA;
	color: #0F4987;
}

.inputbox:focus:-moz-placeholder {
	color: transparent;
}

.inputbox:focus::-webkit-input-placeholder {
	color: transparent;
}


/* Form button styles
---------------------------------------- */

a.button1, input.button1, input.button3, a.button2, input.button2 {
	color: map-get($defaultColors, white);
	background-color: map-get($colors, medbg);
	&:hover{
		color: map-get($defaultColors, white);
		background-color: map-get($colors, darkbg);
	}
}

a.button1, input.button1 {
	border-color: #666666;
}

input.button3 {
	background-image: none;
}

/* <a> button in the style of the form buttons */
a.button1, a.button2 {
	color: map-get($defaultColors, black);
}

/* Hover states */
a.button1:hover, input.button1:hover, a.button2:hover, input.button2:hover, input.button3:hover {
	border-color: none;
	color: map-get($defaultColors, white);
}

/* Focus states */
input.button1:focus, input.button2:focus, input.button3:focus {
	border-color: none;
	color: map-get($defaultColors, white);
}

input.disabled {
	color: #666666;
}

/* jQuery popups
---------------------------------------- */
.phpbb_alert {
	background-color: map-get($defaultColors, white);
	border-color: #999999;
}
#darken {
	background-color: map-get($defaultColors, black);
}

#loading_indicator {
	background-color: map-get($defaultColors, white);
	background-image: url("../../images/loading.gif");
}

.dropdown-extended ul li {
	border-top-color: map-get($defaultColors, lightgray);
}

.dropdown-extended ul li:hover {
	background-color: #CFE1F6; /* Very light blue */
	color: map-get($defaultColors, black);
}

.dropdown-extended .header, .dropdown-extended .footer {
	border-color: map-get($defaultColors, lightgray);
	color: map-get($defaultColors, black);
}

.dropdown-extended .footer {
	border-top-style: solid;
	border-top-width: 1px;
}

.dropdown-extended .header {
	background: map-get($colors, darkbg);
	a{
		color: map-get($defaultColors, white);
	}
}

.dropdown .pointer-inner {
	border-color: map-get($defaultColors, white) transparent;
}

.dropdown-extended .pointer-inner {
	border-color: map-get($colors, verylightbg) transparent;
}

ul.linklist li.responsive-menu a.responsive-menu-link:before {
	border-color: map-get($defaultColors, white);
}

ul.linklist li.responsive-menu a.responsive-menu-link:hover:before, ul.linklist li.responsive-menu.visible a.responsive-menu-link:before {
	border-color: map-get($defaultColors, red);
}

.dropdown .dropdown-contents {
	background: map-get($defaultColors, white);
	border-color: #b9b9b9; /* Between ccc and 808080 gray */
	box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.2);
}

.dropdown-up .dropdown-contents {
	box-shadow: 1px 0 5px rgba(0, 0, 0, 0.2);
}

.dropdown li, .dropdown li li {
	border-color: map-get($defaultColors, lightgray);
}

.dropdown li.separator {
	border-color: map-get($defaultColors, lightgray);
}

/* Notifications
---------------------------------------- */

.notification_list p.notification-time {
	color: map-get($defaultColors, mediumgray);
}

li.notification-reported strong, li.notification-disapproved strong {
	color: map-get($defaultColors, red);
}

#nav-breadcrumbs li span a {
	background: map-get($colors, lightbg);
	color: map-get($defaultColors, white);
	transition: background 2s ease;
	transition-duration: 0.5s;
	&:after {
		border-top: 12px solid transparent;
		border-bottom: 12px solid transparent;
		border-left: 12px solid map-get($colors, lightbg);
		transition: background 2s ease; 
		transition-duration: 0.8s;
	}
	&:before {
		border-top: 12px solid transparent;
		border-bottom: 12px solid transparent;
		border-left: 12px solid map-get($defaultColors, white);
	}
	&:hover {
		background: map-get($colors, medmedbg);
		color: map-get($defaultColors, white);
		text-shadow: 1px 1px 3px map-get($defaultColors, black);
		&:after {
			border-left-color: map-get($colors, medmedbg);
		}
	}
}
			
.new-post{
	background: map-get($colors, medbg);
	color: map-get($defaultColors, white);
	transition: 0.4s ease-in;
	&:hover{
		background: map-get($colors, darkbg);
		color: map-get($defaultColors, white);
	}
}
.forum-image img{
	background: map-get($colors, darkbg);
	border-radius: 8px;
}
.forum-title {
	background: map-get($colors, darkbg);
	a{
		color: map-get($defaultColors, white);
	}
	div{
		color: map-get($defaultColors, white);
	}
	&:hover{
		background: map-get($colors, medmedbg);
	}
	p{
		color: map-get($defaultColors, white);
	}
}
.postbody{
	background: map-get($defaultColors, white);
	small{
		color: map-get($colors, medmedbg);
	}
}
.fa{
	color: map-get($defaultColors, white);
	background: map-get($colors, medbg);
}
.right-pointer{
	border-color: transparent transparent transparent map-get($defaultColors, lightgray);
}
.right-pointer-inner{
	border-color: transparent transparent transparent map-get($defaultColors, white);
}
.left-pointer{
	border-color: transparent map-get($defaultColors, lightgray) transparent transparent;
}
.left-pointer-inner{
	border-color: transparent map-get($defaultColors, white) transparent transparent;
}
.posting-title a{
	background: map-get($colors, medbg);
	color: map-get($defaultColors, white);
}

/*============================================================
Avatar
=============================================================*/

/*============================================================
Profile
=============================================================*/
.profile-info{
	background: map-get($colors, darkbg);
}
.user-profile-actions{
	background: map-get($defaultColors, white);
}
.profile-moderate-actions{
	svg{
		path{
			fill: map-get($colors, meddarkbg);
		}
	}
}
.user-profile-stats dl.details dd{
	background: map-get($colors, medbg);
	color: map-get($defaultColors, white);
}
.user-profile-stats dl.details dd a{
	color: map-get($defaultColors, white);
}
.user-profile-about{
	background: map-get($defaultColors, lightgray);
}

/*============================================================
PM
=============================================================*/
.action-bar.bar-top{
	.button:hover{
		color: map-get($defaultColors, white);
	}
}

/*============================================================
FAQ
=============================================================*/

/*============================================================
Market
=============================================================*/

/*============================================================
Inventory
=============================================================*/

/*============================================================
Crafting
=============================================================*/

/*============================================================
Default callable classes.
============================================================= */
.white-bg{
	background: map-get($defaultColors, white);
}
.white-color{
	color: map-get($defaultColors, white);
}
.black-bg{
	background: map-get($defaultColors, black);
}
.black-color{
	color: map-get($defaultColors, black);
}
.gray-bg{
	background: map-get($defaultColors, gray);
}
.gray-color{
	color: map-get($defaultColors, gray);
}
.mediumgray-bg{
	background: map-get($defaultColors, mediumgray);
}
.mediumgray-color{
	color: map-get($defaultColors, mediumgray);
}
.lightgray-bg{
	background: map-get($defaultColors, lightgray);
}
.lightgray-color{
	color: map-get($defaultColors, lightgray);
}
.verylightgray-bg{
	background: map-get($defaultColors, verylightgray);
}
.verylightgray-color{
	color: map-get($defaultColors, verylightgray);
}
.smallgray-bg{
	background: map-get($defaultColors, smallgray);
}
.smallgray-color{
	color: map-get($defaultColors, smallgray);
}
.red-bg{
	background: map-get($defaultColors, red);
}
.red-color{
	color: map-get($defaultColors, red);
}
.maroon-bg{
	background: map-get($defaultColors, maroon);
}
.maroon-color{
	color: map-get($defaultColors, maroon);
}
.spring-bg{
	background: map-get($defaultColors, spring);
}
.spring-color{
	color: map-get($defaultColors, spring);
}
.green-bg{
	background: map-get($defaultColors, green);
}
.green-color{
	color: map-get($defaultColors, green);
}
.forest-bg{
	background: map-get($defaultColors, forest);
}
.forest-color{
	color: map-get($defaultColors, forest);
}
.yellow-bg{
	background: map-get($defaultColors, yellow);
}
.yellow-color{
	color: map-get($defaultColors, yellow);
}
.gold-bg{
	background: map-get($defaultColors, gold);
}
.gold-color{
	color: map-get($defaultColors, gold);
}
.goldenrod-bg{
	background: map-get($defaultColors, goldenrod);
}
.goldenrod-color{
	color: map-get($defaultColors, goldenrod);
}
.orange-bg{
	background: map-get($defaultColors, orange);
}
.orange-color{
	color: map-get($defaultColors, orange);
}
.silver-bg{
	background: map-get($defaultColors, silver);
}
.silver-color{
	color: map-get($defaultColors, silver);
}
.skyblue-bg{
	background: map-get($defaultColors, skyblue);
}
.skyblue-color{
	color: map-get($defaultColors, skyblue);
}
.blue-bg{
	background: map-get($defaultColors, blue);
}
.blue-color{
	color: map-get($defaultColors, blue);
}
.darkblue-bg{
	background: map-get($defaultColors, darkblue);
}
.darkblue-color{
	color: map-get($defaultColors, darkblue);
}
.blueviolet-bg{
	background: map-get($defaultColors, blueviolet);
}
.blueviolet-color{
	color: map-get($defaultColors, blueviolet);
}
.periwinkle-bg{
	background: map-get($defaultColors, periwinkle);
}
.periwinkle-color{
	color: map-get($defaultColors, periwinkle);
}
.lavender-bg{
	background: map-get($defaultColors, lavender);
}
.lavender-color{
	color: map-get($defaultColors, lavender);
}
.purple-bg{
	background: map-get($defaultColors, purple);
}
.purple-color{
	color: map-get($defaultColors, purple);
}
.magenta-bg{
	background: map-get($defaultColors, magenta);
}
.magenta-color{
	color: map-get($defaultColors, magenta);
}
.babypink-bg{
	background: map-get($defaultColors, babypink);
}
.babypink-color{
	color: map-get($defaultColors, babypink);
}
.brown-bg{
	background: map-get($defaultColors, brown);
}
.brown-color{
	color: map-get($defaultColors, brown);
}
.indigo-bg{
	background: map-get($defaultColors, indigo);
}
.indigo-color{
	color: map-get($defaultColors, indigo);
}
.fadeddarkbg-bg{
	background: map-get($colors, fadeddarkbg);
}
.fadeddarkbg-color{
	color: map-get($colors, fadeddarkbg);
}
.lightbg-bg{
	background: map-get($colors, lightbg);
}
.lightbg-bg:hover{
	background: map-get($colors, medbg);
}
.lightbg-color{
	color: map-get($colors, lightbg);
}
.medbg-bg{
	background: map-get($colors, medbg);
}
.medbg-bg:hover{
	background: map-get($colors, darkbg);
}
.medbg-color{
	color: map-get($colors, medbg);
}
.medmedbg-bg{
	background: map-get($colors, darkbg);
}
.medmedbg-bg:hover{
	background: map-get($colors, darkbg);
}
.medmedbg-color{
	color: map-get($colors, medmedbg);
}
.darkbg-bg{
	background: map-get($colors, darkbg);
}
.darkbg-bg:hover{
	background: map-get($colors, verydarkbg);
}
.darkbg-color{
	color: map-get($colors, darkbg);
}
.verydarkbg-bg{
	background: map-get($colors, verydarkbg);
}
.verydarkbg-color{
	color: map-get($colors, verydarkbg);
}
.verylightbg-bg{
	background: map-get($colors, verylightbg);
}
.verylightbg-color{
	color: map-get($colors, verylightbg);
}
.verylightbg2-bg{
	background: map-get($colors, verylightbg2);
}
.verylightbg2-color{
	color: map-get($colors, verylightbg2);
}
.verylightbg3-bg{
	background: map-get($colors, verylightbg3);
}
.verylightbg3-color{
	color: map-get($colors, verylightbg3);
}
.analogouslightbg-bg{
	background: map-get($colors, analogouslightbg);
}
.analogouslightbg-bg:hover{
	background: map-get($colors, analogouslightbg);
}
.analogouslightbg-color{
	color: map-get($colors, analogouslightbg);
}
.analogouslightbg-border{
	border: 5px solid map-get($colors, analogouslightbg);
}
.analogousmeddarkbg-bg{
	background: map-get($colors, analogousmeddarkbg);
}
.analogousmeddarkbg-bg:hover{
	background: map-get($colors, analogousmeddarkbg);
}
.analogousmeddarkbg-color{
	color: map-get($colors, analogousmeddarkbg);
}
.analogousmeddarkbg-border{
	border: 5px solid map-get($colors, analogousmeddarkbg);
}
.analogouslightbg2-bg{
	background: map-get($colors, analogouslightbg2);
}
.analogouslightbg2-bg:hover{
	background: map-get($colors, analogouslightbg2);
}
.analogouslightbg2-color{
	color: map-get($colors, analogouslightbg2);
}
.analogouslightbg2-border{
	border: 5px solid map-get($colors, analogouslightbg2);
}
.analogousdarkbg-bg{
	background: map-get($colors, analogousdarkbg);
}
.analogousdarkbg-bg:hover{
	background: map-get($colors, analogousdarkbg);
}
.analogousdarkbg-color{
	color: map-get($colors, analogousdarkbg);
}
.analogousdarkbg-border{
	border: 5px solid map-get($colors, analogousdarkbg);
}
.analogousdarkdarkbg-bg{
	background: map-get($colors, analogousdarkdarkbg);
}
.analogousdarkdarkbg-bg:hover{
	background: map-get($colors, analogousdarkdarkbg);
}
.analogousdarkdarkbg-color{
	color: map-get($colors, analogousdarkdarkbg);
}
.analogousdarkdarkbg-border{
	border: 5px solid map-get($colors, analogousdarkdarkbg);
}
.analogousverydarkbg-bg{
	background: map-get($colors, analogousverydarkbg);
}
.analogousverydarkbg-bg:hover{
	background: map-get($colors, analogousdarkbg);
}
.analogousverydarkbg-color{
	color: map-get($colors, analogousverydarkbg);
}
.analogousverydarkbg-border{
	border: 5px solid map-get($colors, analogousverydarkbg);
}
.darkmedbg-bg{
	background: map-get($colors, darkmedbg);
}
.darkmedbg-bg:hover{
	background: map-get($colors, darkmedbg);
}
.darkmedbg-color{
	color: map-get($colors, darkmedbg);
}
.darkmedbg-border{
	border: 5px solid map-get($colors, darkmedbg);
}
.darkmedmedbg-nonhover-bg{
	background: map-get($colors, darkmedmedbg);
}
.darkmedmedbg-bg{
	background: map-get($colors, darkmedmedbg);
}
.darkmedmedbg-bg:hover{
	background: map-get($colors, medbg);
}
.darkmedmedbg-color{
	color: map-get($colors, darkmedmedbg);
}
.darkmedmedbg-border{
	border: 5px solid map-get($colors, darkmedmedbg);
}
.darkmeddarkbg-bg{
	background: map-get($colors, darkmeddarkbg);
}
.darkmeddarkbg-bg:hover{
	background: map-get($colors, darkmeddarkbg);
}
.darkmeddarkbg-color{
	color: map-get($colors, darkmeddarkbg);
}
.darkmeddarkbg-border{
	border: 5px solid map-get($colors, darkmeddarkbg);
}
.darkdarkbg-bg{
	background: map-get($colors, darkdarkbg);
}
.darkdarkbg-bg:hover{
	background: map-get($colors, darkdarkbg);
}
.darkdarkbg-color{
	color: map-get($colors, darkdarkbg);
}
.darkdarkbg-border{
	border: 5px solid map-get($colors, darkdarkbg);
}
.darklightbg-bg{
	background: map-get($colors, darklightbg);
}
.darklightbg-bg:hover{
	background: map-get($colors, darklightbg);
}
.darklightbg-color{
	color: map-get($colors, darklightbg);
}
.darklightbg-border{
	border: 5px solid map-get($colors, darklightbg);
}
.dawnmedbg-bg{
	background: map-get($colors, dawnmedbg);
}
.dawnmedbg-bg:hover{
	background: map-get($colors, dawnmedbg);
}
.dawnmedbg-color{
	color: map-get($colors, dawnmedbg);
}
.dawnmedbg-border{
	border: 5px solid map-get($colors, dawnmedbg);
}
.dawnmedmedbg-bg{
	background: map-get($colors, dawnmedmedbg);
}
.dawnmedmedbg-bg:hover{
	background: map-get($colors, dawnmedmedbg);
}
.dawnmedmedbg-color{
	color: map-get($colors, dawnmedmedbg);
}
.dawnmedmedbg-border{
	border: 5px solid map-get($colors, dawnmedmedbg);
}
.dawnmeddarkbg-bg{
	background: map-get($colors, dawnmeddarkbg);
}
.dawnmeddarkbg-bg:hover{
	background: map-get($colors, dawnmeddarkbg);
}
.dawnmeddarkbg-color{
	color: map-get($colors, dawnmeddarkbg);
}
.dawnmeddarkbg-border{
	border: 5px solid map-get($colors, dawnmeddarkbg);
}
.dawndarkbg-bg{
	background: map-get($colors, dawndarkbg);
}
.dawndarkbg-bg:hover{
	background: map-get($colors, dawndarkbg);
}
.dawndarkbg-color{
	color: map-get($colors, dawndarkbg);
}
.dawndarkbg-border{
	border: 5px solid map-get($colors, dawndarkbg);
}
.lightmedbg-bg{
	background: map-get($colors, lightmedbg);
}
.lightmedbg-bg:hover{
	background: map-get($colors, lightmedbg);
}
.lightmedbg-color{
	color: map-get($colors, lightmedbg);
}
.lightmedbg-border{
	border: 5px solid map-get($colors, lightmedbg);
}
.lightmedlightbg-bg{
	background: map-get($colors, lightmedlightbg);
}
.lightmedlightbg-bg:hover{
	background: map-get($colors, lightmedlightbg);
}
.lightmedlightbg-color{
	color: map-get($colors, lightmedlightbg);
}
.lightmedlightbg-border{
	border: 5px solid map-get($colors, lightmedlightbg);
}
.lightlightbg-bg{
	background: map-get($colors, lightlightbg);
}
.lightlightbg-bg:hover{
	background: map-get($colors, lightlightbg);
}
.lightlightbg-color{
	color: map-get($colors, lightlightbg);
}
.lightlightbg-border{
	border: 5px solid map-get($colors, lightlightbg);
}
.lightdarkbg-bg{
	background: map-get($colors, lightdarkbg);
}
.lightdarkbg-bg:hover{
	background: map-get($colors, lightdarkbg);
}
.lightdarkbg-color{
	color: map-get($colors, lightdarkbg);
}
.lightdarkbg-border{
	border: 5px solid map-get($colors, lightdarkbg);
}
.lightverylightbg-bg{
	background: map-get($colors, lightverylightbg);
}
.lightverylightbg-bg:hover{
	background: map-get($colors, lightverylightbg);
}
.lightverylightbg-color{
	color: map-get($colors, lightverylightbg);
}
.lightverylightbg-border{
	border: 5px solid map-get($colors, lightverylightbg);
}
.pastellightbg-bg{
	background: map-get($colors, pastellightbg);
}
.pastellightbg-bg:hover{
	background: map-get($colors, pastellightbg);
}
.pastellightbg-color{
	color: map-get($colors, pastellightbg);
}
.pastellightbg-border{
	border: 5px solid map-get($colors, pastellightbg);
}
.pastelverylightbg-bg{
	background: map-get($colors, pastelverylightbg);
}
.pastelverylightbg-bg:hover{
	background: map-get($colors, pastelverylightbg);
}
.pastelverylightbg-color{
	color: map-get($colors, pastelverylightbg);
}
.pastelverylightbg-border{
	border: 5px solid map-get($colors, pastelverylightbg);
}
.pastelmedbg-bg{
	background: map-get($colors, pastelmedbg);
}
.pastelmedbg-bg:hover{
	background: map-get($colors, pastelmedbg);
}
.pastelmedbg-color{
	color: map-get($colors, pastelmedbg);
}
.pastelmedbg-border{
	border: 5px solid map-get($colors, pastelmedbg);
}
.pastelmedlightbg-bg{
	background: map-get($colors, pastelmedlightbg);
}
.pastelmedlightbg-bg:hover{
	background: map-get($colors, pastelmedlightbg);
}
.pastelmedlightbg-color{
	color: map-get($colors, pastelmedlightbg);
}
.pastelmedlightbg-border{
	border: 5px solid map-get($colors, pastelmedlightbg);
}
.pasteldarkbg-bg{
	background: map-get($colors, pasteldarkbg);
}
.pasteldarkbg-bg:hover{
	background: map-get($colors, pasteldarkbg);
}
.pasteldarkbg-color{
	color: map-get($colors, pasteldarkbg);
}
.pasteldarkbg-border{
	border: 5px solid map-get($colors, pasteldarkbg);
}
.sunsetdarkbg-bg{
	background: map-get($colors, sunsetdarkbg);
}
.sunsetdarkbg-bg:hover{
	background: map-get($colors, sunsetdarkbg);
}
.sunsetdarkbg-color{
	color: map-get($colors, sunsetdarkbg);
}
.sunsetdarkbg-border{
	border: 5px solid map-get($colors, sunsetdarkbg);
}
.sunsetmedlightbg-bg{
	background: map-get($colors, sunsetmedlightbg);
}
.sunsetmedlightbg-bg:hover{
	background: map-get($colors, sunsetmedlightbg);
}
.sunsetmedlightbg-color{
	color: map-get($colors, sunsetmedlightbg);
}
.sunsetmedlightbg-border{
	border: 5px solid map-get($colors, sunsetmedlightbg);
}
.sunsetmeddarkbg-bg{
	background: map-get($colors, sunsetmeddarkbg);
}
.sunsetmeddarkbg-bg:hover{
	background: map-get($colors, sunsetmeddarkbg);
}
.sunsetmeddarkbg-color{
	color: map-get($colors, sunsetmeddarkbg);
}
.sunsetmeddarkbg-border{
	border: 5px solid map-get($colors, sunsetmeddarkbg);
}
.sunsetmedmedbg-bg{
	background: map-get($colors, sunsetmedmedbg);
}
.sunsetmedmedbg-bg:hover{
	background: map-get($colors, sunsetmedmedbg);
}
.sunsetmedmedbg-color{
	color: map-get($colors, sunsetmedmedbg);
}
.sunsetmedmedbg-border{
	border: 5px solid map-get($colors, sunsetmedmedbg);
}
.sunsetmedbg-bg{
	background: map-get($colors, sunsetmedbg);
}
.sunsetmedbg-bg:hover{
	background: map-get($colors, sunsetmedbg);
}
.sunsetmedbg-color{
	color: map-get($colors, sunsetmedbg);
}
.sunsetmedbg-border{
	border: 5px solid map-get($colors, sunsetmedbg);
}
.sunsetdarkdarkbg-bg{
	background: map-get($colors, sunsetdarkdarkbg);
}
.sunsetdarkdarkbg-bg:hover{
	background: map-get($colors, sunsetmedbg);
}
.sunsetdarkdarkbg-color{
	color: map-get($colors, sunsetdarkdarkbg);
}
.sunsetdarkdarkbg-border{
	border: 5px solid map-get($colors, sunsetdarkdarkbg);
}
.sunsetverydarkbg-bg{
	background: map-get($colors, sunsetverydarkbg);
}
.sunsetverydarkbg-bg:hover{
	background: map-get($colors, sunsetdarkbg);
}
.sunsetverydarkbg-color{
	color: map-get($colors, sunsetverydarkbg);
}
.sunsetverydarkbg-border{
	border: 5px solid map-get($colors, sunsetverydarkbg);
}
.dawn-gradient{
	background: map-get($colors, dawnmedmedbg);
	background: linear-gradient(90deg, map-get($colors, dawnmedmedbg) 0%, map-get($colors, dawnmeddarkbg) 100%);
}
.sunset-gradient{
	background: sunsetmedmedbg;
	background: linear-gradient(90deg, map-get($colors, sunsetmedmedbg) 0%, map-get($colors, sunsetmedlightbg) 50%, map-get($colors, sunsetmedmedbg) 100%);
}
.white-border{
	border: 5px solid map-get($defaultColors, white);
}
.black-border{
	border: 5px solid map-get($defaultColors, black);
}
.gray-border{
	border: 5px solid map-get($defaultColors, gray);
}
.mediumgray-border{
	border: 5px solid map-get($defaultColors, mediumgray);
}
.lightgray-border{
	border: 5px solid map-get($defaultColors, lightgray);
}
.verylightgray-border{
	border: 5px solid map-get($defaultColors, verylightgray);
}
.smallgray-border{
	border: 5px solid map-get($defaultColors, smallgray);
}
.red-border{
	border: 5px solid map-get($defaultColors, red);
}
.maroon-border{
	border: 5px solid map-get($defaultColors, maroon);
}
.spring-border{
	border: 5px solid map-get($defaultColors, spring);
}
.green-border{
	border: 5px solid map-get($defaultColors, green);
}
.forest-border{
	border: 5px solid map-get($defaultColors, forest);
}
.yellow-border{
	border: 5px solid map-get($defaultColors, yellow);
}
.gold-border{
	border: 5px solid map-get($defaultColors, gold);
}
.goldenrod-border{
	border: 5px solid map-get($defaultColors, goldenrod);
}
.orange-border{
	border: 5px solid map-get($defaultColors, orange);
}
.silver-border{
	border: 5px solid map-get($defaultColors, silver);
}
.skyblue-border{
	border: 5px solid map-get($defaultColors, skyblue);
}
.blue-border{
	border: 5px solid map-get($defaultColors, blue);
}
.darkblue-border{
	border: 5px solid map-get($defaultColors, darkblue);
}
.blueviolet-border{
	border: 5px solid map-get($defaultColors, blueviolet);
}
.periwinkle-border{
	border: 5px solid map-get($defaultColors, periwinkle);
}
.lavender-border{
	border: 5px solid map-get($defaultColors, lavender);
}
.purple-border{
	border: 5px solid map-get($defaultColors, purple);
}
.magenta-border{
	border: 5px solid map-get($defaultColors, magenta);
}
.babypink-border{
	border: 5px solid map-get($defaultColors, babypink);
}
.brown-border{
	border: 5px solid map-get($defaultColors, brown);
}
.indigo-border{
	border: 5px solid map-get($defaultColors, indigo);
}
.fadeddarkbg-border{
	border: 5px solid map-get($colors, fadeddarkbg);
}
.lightbg-border{
	border: 5px solid map-get($colors, lightbg);
}
.medbg-border{
	border: 5px solid map-get($colors, medbg);
}
.medmedbg-border{
	border: 5px solid map-get($colors, medmedbg);
}
.darkbg-border{
	border: 5px solid map-get($colors, darkbg);
}
.verydarkbg-border{
	border: 5px solid map-get($colors, verydarkbg);
}
.verylightbg-border{
	border: 5px solid map-get($colors, verylightbg);
}
.verylightbg2-border{
	border: 5px solid map-get($colors, verylightbg2);
}
.verylightbg3-border{
	border: 5px solid map-get($colors, verylightbg3);
}
@media only screen and (min-width: 700px){
	.postbody{
		box-shadow: 1px 1px 3px map-get($defaultColors, gray);
	}
}